require('../../node_modules/uikit/dist/js/uikit.min.js');
import UIkit from 'uikit';


UIkit.scrollspy('.page-home .usps', {target: '> div > div > div', cls:'uk-animation-fade', repeat: true, delay: 200, media: '@m'});

UIkit.scrollspy('.home-header h1', {cls:'uk-animation-fade', repeat: true, delay: 200, media: '@m'});
UIkit.scrollspy('.home-header h1 + p', {cls:'uk-animation-fade', repeat: true, delay: 200, media: '@m'});
UIkit.scrollspy('.home-header h1 + p + p', {cls:'uk-animation-fade', repeat: true, delay: 200, media: '@m'});

UIkit.scrollspy('.home-intro h2', {cls:'uk-animation-fade', repeat: true, delay: 200, media: '@m'});
UIkit.scrollspy('.home-intro h2 + p', {cls:'uk-animation-fade', repeat: true, delay: 200, media: '@m'});
UIkit.scrollspy('.home-intro h2 + p + p', {cls:'uk-animation-fade', repeat: true, delay: 200, media: '@m'});

UIkit.scrollspy('.page-home .home-infraroodpanelen .slider2', {target: '> li', cls:'uk-animation-fade', repeat: true, delay: 200, media: '@m'});

UIkit.scrollspy('.home-particulier-industrieel-left-p1', {cls:'uk-animation-slide-left-small', repeat: true, delay: 200, media: '@m'});
UIkit.scrollspy('.home-particulier-industrieel-left-p2', {cls:'uk-animation-slide-left-medium', repeat: true, delay: 400, media: '@m'});

UIkit.scrollspy('.over-ons-main-block-right h2', {cls:'uk-animation-fade', repeat: true, delay:200, media: '@m'});
UIkit.scrollspy('.over-ons-main-block-right h2 + p', {cls:'uk-animation-fade', repeat: true, delay: 400, media: '@m'});

UIkit.parallax('.home-overlay img', {x: '280px, 0', /*y: '-40px, 0',*/ easing: 0.5, /*media: '@m',*/ viewport: 0.25, media: '@m'});


$(document).on('click', ".mobile-menu-icon", function ( event ) {
    $('.mobile-menu-icon label').removeClass('return');
    $('.mobile-menu-overlay').addClass('menu-fixed');
    $('html').css('overflow-y','hidden');

    if($(this).find('input').is(':checked')) {
        $('.mobile-menu-block').addClass('open');
        $('body').addClass('filter-open');
    } else {
        $('.mobile-menu-block').removeClass('open');
        $('body').removeClass('filter-open');
    }
});

$(document).on('click', ".mobile-menu-overlay.menu-fixed", function ( event ) {
    $('.mobile-menu-overlay').removeClass('menu-fixed');
    $('html').css('overflow-y','visible');
    $('.mobile-menu-block').removeClass('open');

    if($('.mobile-menu-icon').find('input').is(':checked')) {
        $('.mobile-menu-icon label').addClass('return');
        $('.mobile-menu-icon input').prop('checked', false);
        $('body').removeClass('filter-open');
    } else {
        $('.mobile-menu-icon label').removeClass('return');
        $('.mobile-menu-icon input').prop('checked', true);
        $('body').removeClass('filter-open');
    }
});

$(document).on('click', ".mobile-menu-block.open .close-menu", function ( event ) {
    $('.mobile-menu-overlay').removeClass('menu-fixed');
    $('html').css('overflow-y','visible');

    if($('.mobile-menu-icon').find('input').is(':checked')) {
        $('.mobile-menu-icon label').addClass('return');
        $('.mobile-menu-icon input').prop('checked', false);
        $('body').removeClass('filter-open');
    } else {
        $('.mobile-menu-icon label').removeClass('return');
        $('.mobile-menu-icon input').prop('checked', true);
        $('body').removeClass('filter-open');
    }
});

UIkit.scrollspy('.scroll-fade-fast', {cls:'uk-animation-fade', repeat: true, delay: 100});
UIkit.scrollspy('.scroll-fade-slow', {cls:'uk-animation-fade', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-fade-1', {cls:'uk-animation-fade', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-fade-2', {cls:'uk-animation-fade', repeat: true, delay: 400});
UIkit.scrollspy('.scroll-fade-3', {cls:'uk-animation-fade', repeat: true, delay: 600});
UIkit.scrollspy('.scroll-left-small', {cls:'uk-animation-slide-left-small', repeat: true, delay: 100});
UIkit.scrollspy('.scroll-right-small', {cls:'uk-animation-slide-right-small', repeat: true, delay: 100});
UIkit.scrollspy('.scroll-left-medium', {cls:'uk-animation-slide-left-medium', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-right-medium', {cls:'uk-animation-slide-right-medium', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-left', {cls:'uk-animation-slide-left', repeat: true, delay: 250});
UIkit.scrollspy('.scroll-right', {cls:'uk-animation-slide-right', repeat: true, delay: 250});
UIkit.scrollspy('.scroll-left-large', {cls:'uk-animation-slide-left', repeat: true, delay: 350});
UIkit.scrollspy('.scroll-right-large', {cls:'uk-animation-slide-right', repeat: true, delay: 350});

/*UIkit.scrollspy('.page-home .usps', {target: '> div > div > div', cls:'uk-animation-fade', repeat: true, delay: 300});

UIkit.scrollspy('.home-header h1', {cls:'uk-animation-fade', repeat: true, delay: 100});
UIkit.scrollspy('.home-header h1 + p', {cls:'uk-animation-fade', repeat: true, delay: 200});
UIkit.scrollspy('.home-header h1 + p + p', {cls:'uk-animation-fade', repeat: true, delay: 300});

UIkit.scrollspy('.home-intro h2', {cls:'uk-animation-fade', repeat: true, delay: 100});
UIkit.scrollspy('.home-intro h2 + p', {cls:'uk-animation-fade', repeat: true, delay: 200});
UIkit.scrollspy('.home-intro h2 + p + p', {cls:'uk-animation-fade', repeat: true, delay: 300});

UIkit.scrollspy('.page-home .home-infraroodpanelen .slider2', {target: '> li', cls:'uk-animation-fade', repeat: true, delay: 300});

UIkit.scrollspy('.home-particulier-industrieel-left-p1', {cls:'uk-animation-slide-left-small', repeat: true, delay: 200});
UIkit.scrollspy('.home-particulier-industrieel-left-p2', {cls:'uk-animation-slide-left-medium', repeat: true, delay: 400});

UIkit.scrollspy('.over-ons-main-block-right h2', {cls:'uk-animation-fade', repeat: true, delay: 100});
UIkit.scrollspy('.over-ons-main-block-right h2 + p', {cls:'uk-animation-fade', repeat: true, delay: 200});

UIkit.parallax('.home-overlay img', {x: '280px, 0', /!*y: '-40px, 0',*!/ easing: 0.5, /!*media: '@m',*!/ viewport: 0.25});*/


